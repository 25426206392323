module.exports = {
  selectLanguage: 'Selecione o seu idioma',
  connectFrom:
    'Please join the cause now. <strong>Every dollar counts</strong>',
  partOfThis: 'International Music Benefit ',
  weHave: 'Live-streaming:',
  donateNow: 'DONATE FOR VENEZUELA',
  date: 'February 22nd, 2019',
  hour: 'Cucuta, Colombia',
  collectCaption:
    '<strong>Venezuela Aid Live</strong>, led by <strong>Sir Richard Branson</strong>, has brought together an array of renowned artists in hopes of raising <strong>$10 million</strong> which will be the starting point towards raising <strong>$100 Million total in humanitarian aid & donations from governments.</strong>',
  timeToAct:
    'United Voices for<br> <strong><span>Venezuela</span></strong>',
  reasons:
    '<ul>\n' +
    '                    <li>For years, Venezuelans suffered a deterioration in their quality of life, more than 250,000 Venezuelans have been murdered in the country in the last 10 years, 33% of children living in poverty suffer from malnutrition and are at risk of delays. in his cognitive and psychomotor development. Thousands of children have died due to lack of access to medicines and medical treatments. And more than 1,750,000 Venezuelans are in Colombia as migrants.</li>\n' +
    '                    <li>On February 22, businessmen Richard Branson, Bruno and Fernán Ocampo. They organized the VENEZUELA AID LIVE concert in Cúcuta, through which two very important goals were achieved.\n' +
    '                    <br/>First: Achieve awareness and evidence before the world, the serious humanitarian emergency that Venezuela is currently experiencing and the dimensions of the migration crisis that this has generated in Latin America.'+
    '                    <br/>Second: Raise funds to alleviate some of the most urgent needs in the most affected areas.'+
    '                    </li>\n' +
    '                  </ul>',
  timeToActAction: 'Please join the cause now. Every dollar counts',
  helpToShare: 'Share on: ',
  peopleSay: 'La gente dice',
  peopleSayText:
    '“Sufro al ver a mi mamá quejándose de dolor porque no hay nada que se pueda hacer con su cáncer, las\n' +
    '                medicinas de la quimioterapia aquí no existen desde hace mucho, y no tengo los medios para\n' +
    '                conseguirlas por fuera, ya todos sabemos que no va a sobrevivi”',
  peopleSayFact:
    '<span>50%</span> de los pacientes con <span>cáncer</span> en Venezuela no reciben tratamiento para\n' +
    '                  el <span>dolor</span>',
  newsFronVzla: '<h2>News from <span>Venezuela</span></h2>',
  pressReleases: '<h2>Press<span> Release</span></h2>',
  pressCredentials: '<h2>Press <span>Credentials</span></h2>',
  pressCredentialsActions:
    '<a rel="nofollow" href="https://goo.gl/forms/uOwEZUJklRWQCM8H2"><h4 style="color:#0449d0;text-decoration: underline;">Apply Here</h4></a>',
  liveStreaming: 'Live Now',
  sponsorByText: '<h2>Alliances</h2>',
  mainBenefactorsText: '<h2>Main <span>Benefactors</span></h2>'

}
